import type { DeliveryService } from '~/types/Checkout';

export const DELIVERY_SERVICES: { [key: string]: DeliveryService } = {
  boxberry: { slug: 'boxberry', title: 'Boxberry' },
  sdek: { slug: 'sdek', title: 'СДЭК' },
  russian_post: { slug: 'russian_post', title: 'Почта России' },
  ems: { slug: 'ems', title: 'EMS' },
  'express-mkad': { slug: 'express-mkad', title: 'Яндекс GO' },
  pick_up_from_the_store: { slug: 'pick_up_from_the_store', title: 'Магазин Hellride' },
  shop: { slug: 'pick_up_from_the_store', title: 'Магазин Hellride' }, // костыль
};
