import { DELIVERY_SERVICES } from '~/constants/deliveryServices';

export const DELIVERY_TYPES = {
  shopPickup: {
    slug: 'shopPickup',
    name: 'Самовывоз из Магазина Hellride',
    availableServices: [DELIVERY_SERVICES.shop],
    delivery_period_min: 0,
    delivery_period_max: 1,
    delivery_price: 0,
  },

  selfPickup: {
    slug: 'selfPickup',
    name: 'Самовывоз из пункта выдачи',
    availableServices: [],
    delivery_period_min: 1,
    delivery_period_max: 2,
    delivery_price: 200,
  },

  courier: {
    slug: 'courier',
    name: 'Курьером до двери',
    availableServices: [],
    delivery_period_min: 1,
    delivery_period_max: 2,
    delivery_price: 200,
  },

  russianPost: {
    slug: 'russianPost',
    name: 'В отделение Почты России',
    availableServices: [DELIVERY_SERVICES.russian_post],
    delivery_period_min: 6,
    delivery_period_max: 10,
    delivery_price: 200,
  },
};
