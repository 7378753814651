import type { Location } from '~/types/Checkout';

export const defaultLocations: Location[] = [
  {
    value: 'г Москва',
    unrestricted_value: '101000, г Москва',
    data: {
      postal_code: '101000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Центральный',
      region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      region_kladr_id: '7700000000000',
      region_iso_code: 'RU-MOW',
      region_with_type: 'г Москва',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Москва',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      city_kladr_id: '7700000000000',
      city_with_type: 'г Москва',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Москва',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      fias_code: null,
      fias_level: '1',
      fias_actuality_state: '0',
      kladr_id: '7700000000000',
      geoname_id: '524901',
      capital_marker: '0',
      okato: '45000000000',
      oktmo: '45000000',
      tax_office: '7700',
      tax_office_legal: '7700',
      timezone: null,
      geo_lat: '55.75396',
      geo_lon: '37.620393',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Санкт-Петербург',
    unrestricted_value: '190000, г Санкт-Петербург',
    data: {
      postal_code: '190000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Северо-Западный',
      region_fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
      region_kladr_id: '7800000000000',
      region_iso_code: 'RU-SPE',
      region_with_type: 'г Санкт-Петербург',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Санкт-Петербург',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
      city_kladr_id: '7800000000000',
      city_with_type: 'г Санкт-Петербург',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Санкт-Петербург',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
      fias_code: null,
      fias_level: '1',
      fias_actuality_state: '0',
      kladr_id: '7800000000000',
      geoname_id: '498817',
      capital_marker: '0',
      okato: '40000000000',
      oktmo: '40000000',
      tax_office: '7800',
      tax_office_legal: '7800',
      timezone: null,
      geo_lat: '59.939084',
      geo_lon: '30.315879',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: ['г Ленинград'],
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Самара',
    unrestricted_value: '443000, Самарская обл, г Самара',
    data: {
      postal_code: '443000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Приволжский',
      region_fias_id: 'df3d7359-afa9-4aaa-8ff9-197e73906b1c',
      region_kladr_id: '6300000000000',
      region_iso_code: 'RU-SAM',
      region_with_type: 'Самарская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Самарская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b',
      city_kladr_id: '6300000100000',
      city_with_type: 'г Самара',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Самара',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '6300000100000',
      geoname_id: '499099',
      capital_marker: '2',
      okato: '36401000000',
      oktmo: '36701000',
      tax_office: '6300',
      tax_office_legal: '6300',
      timezone: null,
      geo_lat: '53.195096',
      geo_lon: '50.106868',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Новосибирск',
    unrestricted_value: '630000, Новосибирская обл, г Новосибирск',
    data: {
      postal_code: '630000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Сибирский',
      region_fias_id: '1ac46b49-3209-4814-b7bf-a509ea1aecd9',
      region_kladr_id: '5400000000000',
      region_iso_code: 'RU-NVS',
      region_with_type: 'Новосибирская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Новосибирская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
      city_kladr_id: '5400000100000',
      city_with_type: 'г Новосибирск',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Новосибирск',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '5400000100000',
      geoname_id: '1496747',
      capital_marker: '2',
      okato: '50401000000',
      oktmo: '50701000001',
      tax_office: '5400',
      tax_office_legal: '5400',
      timezone: null,
      geo_lat: '55.028141',
      geo_lon: '82.921117',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Екатеринбург',
    unrestricted_value: '620000, Свердловская обл, г Екатеринбург',
    data: {
      postal_code: '620000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Уральский',
      region_fias_id: '92b30014-4d52-4e2e-892d-928142b924bf',
      region_kladr_id: '6600000000000',
      region_iso_code: 'RU-SVE',
      region_with_type: 'Свердловская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Свердловская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '2763c110-cb8b-416a-9dac-ad28a55b4402',
      city_kladr_id: '6600000100000',
      city_with_type: 'г Екатеринбург',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Екатеринбург',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '2763c110-cb8b-416a-9dac-ad28a55b4402',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '6600000100000',
      geoname_id: '1486209',
      capital_marker: '2',
      okato: '65401000000',
      oktmo: '65701000001',
      tax_office: '6600',
      tax_office_legal: '6600',
      timezone: null,
      geo_lat: '56.838607',
      geo_lon: '60.605514',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: ['г Свердловск'],
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Казань',
    unrestricted_value: '420000, Респ Татарстан, г Казань',
    data: {
      postal_code: '420000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Приволжский',
      region_fias_id: '0c089b04-099e-4e0e-955a-6bf1ce525f1a',
      region_kladr_id: '1600000000000',
      region_iso_code: 'RU-TA',
      region_with_type: 'Респ Татарстан',
      region_type: 'Респ',
      region_type_full: 'республика',
      region: 'Татарстан',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
      city_kladr_id: '1600000100000',
      city_with_type: 'г Казань',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Казань',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '1600000100000',
      geoname_id: '551487',
      capital_marker: '2',
      okato: '92401000000',
      oktmo: '92701000001',
      tax_office: '1600',
      tax_office_legal: '1600',
      timezone: null,
      geo_lat: '55.794438',
      geo_lon: '49.111451',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Нижний Новгород',
    unrestricted_value: '603000, Нижегородская обл, г Нижний Новгород',
    data: {
      postal_code: '603000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Приволжский',
      region_fias_id: '88cd27e2-6a8a-4421-9718-719a28a0a088',
      region_kladr_id: '5200000000000',
      region_iso_code: 'RU-NIZ',
      region_with_type: 'Нижегородская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Нижегородская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '555e7d61-d9a7-4ba6-9770-6caa8198c483',
      city_kladr_id: '5200000100000',
      city_with_type: 'г Нижний Новгород',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Нижний Новгород',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '555e7d61-d9a7-4ba6-9770-6caa8198c483',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '5200000100000',
      geoname_id: '520555',
      capital_marker: '2',
      okato: '22401000000',
      oktmo: '22701000001',
      tax_office: '5200',
      tax_office_legal: '5200',
      timezone: null,
      geo_lat: '56.324133',
      geo_lon: '44.005299',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: ['г Горький'],
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Челябинск',
    unrestricted_value: '454000, Челябинская обл, г Челябинск',
    data: {
      postal_code: '454000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Уральский',
      region_fias_id: '27eb7c10-a234-44da-a59c-8b1f864966de',
      region_kladr_id: '7400000000000',
      region_iso_code: 'RU-CHE',
      region_with_type: 'Челябинская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Челябинская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'a376e68d-724a-4472-be7c-891bdb09ae32',
      city_kladr_id: '7400000100000',
      city_with_type: 'г Челябинск',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Челябинск',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: 'a376e68d-724a-4472-be7c-891bdb09ae32',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '7400000100000',
      geoname_id: '1508291',
      capital_marker: '2',
      okato: '75401000000',
      oktmo: '75701000001',
      tax_office: '7400',
      tax_office_legal: '7400',
      timezone: null,
      geo_lat: '55.160283',
      geo_lon: '61.400856',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Омск',
    unrestricted_value: '644000, Омская обл, г Омск',
    data: {
      postal_code: '644000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Сибирский',
      region_fias_id: '05426864-466d-41a3-82c4-11e61cdc98ce',
      region_kladr_id: '5500000000000',
      region_iso_code: 'RU-OMS',
      region_with_type: 'Омская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Омская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '140e31da-27bf-4519-9ea0-6185d681d44e',
      city_kladr_id: '5500000100000',
      city_with_type: 'г Омск',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Омск',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '140e31da-27bf-4519-9ea0-6185d681d44e',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '5500000100000',
      geoname_id: '1496153',
      capital_marker: '2',
      okato: '52401000000',
      oktmo: '52701000001',
      tax_office: '5500',
      tax_office_legal: '5500',
      timezone: null,
      geo_lat: '54.984912',
      geo_lon: '73.367525',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Ростов-на-Дону',
    unrestricted_value: '344000, Ростовская обл, г Ростов-на-Дону',
    data: {
      postal_code: '344000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Южный',
      region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
      region_kladr_id: '6100000000000',
      region_iso_code: 'RU-ROS',
      region_with_type: 'Ростовская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Ростовская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5',
      city_kladr_id: '6100000100000',
      city_with_type: 'г Ростов-на-Дону',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Ростов-на-Дону',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '6100000100000',
      geoname_id: '501175',
      capital_marker: '2',
      okato: '60401000000',
      oktmo: '60701000001',
      tax_office: '6100',
      tax_office_legal: '6100',
      timezone: null,
      geo_lat: '47.222531',
      geo_lon: '39.718705',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Уфа',
    unrestricted_value: '450000, Респ Башкортостан, г Уфа',
    data: {
      postal_code: '450000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Приволжский',
      region_fias_id: '6f2cbfd8-692a-4ee4-9b16-067210bde3fc',
      region_kladr_id: '0200000000000',
      region_iso_code: 'RU-BA',
      region_with_type: 'Респ Башкортостан',
      region_type: 'Респ',
      region_type_full: 'республика',
      region: 'Башкортостан',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '7339e834-2cb4-4734-a4c7-1fca2c66e562',
      city_kladr_id: '0200000100000',
      city_with_type: 'г Уфа',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Уфа',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '7339e834-2cb4-4734-a4c7-1fca2c66e562',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '0200000100000',
      geoname_id: '479561',
      capital_marker: '2',
      okato: '80401000000',
      oktmo: '80701000001',
      tax_office: '0200',
      tax_office_legal: '0200',
      timezone: null,
      geo_lat: '54.734856',
      geo_lon: '55.957856',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Красноярск',
    unrestricted_value: '660000, Красноярский край, г Красноярск',
    data: {
      postal_code: '660000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Сибирский',
      region_fias_id: 'db9c4f8b-b706-40e2-b2b4-d31b98dcd3d1',
      region_kladr_id: '2400000000000',
      region_iso_code: 'RU-KYA',
      region_with_type: 'Красноярский край',
      region_type: 'край',
      region_type_full: 'край',
      region: 'Красноярский',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '9b968c73-f4d4-4012-8da8-3dacd4d4c1bd',
      city_kladr_id: '2400000100000',
      city_with_type: 'г Красноярск',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Красноярск',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '9b968c73-f4d4-4012-8da8-3dacd4d4c1bd',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '2400000100000',
      geoname_id: '1502026',
      capital_marker: '2',
      okato: '04401000000',
      oktmo: '04701000001',
      tax_office: '2400',
      tax_office_legal: '2400',
      timezone: null,
      geo_lat: '56.009477',
      geo_lon: '92.852449',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Краснодар',
    unrestricted_value: '350000, Краснодарский край, г Краснодар',
    data: {
      postal_code: '350000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Южный',
      region_fias_id: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
      region_kladr_id: '2300000000000',
      region_iso_code: 'RU-KDA',
      region_with_type: 'Краснодарский край',
      region_type: 'край',
      region_type_full: 'край',
      region: 'Краснодарский',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '7dfa745e-aa19-4688-b121-b655c11e482f',
      city_kladr_id: '2300000100000',
      city_with_type: 'г Краснодар',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Краснодар',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '7dfa745e-aa19-4688-b121-b655c11e482f',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '2300000100000',
      geoname_id: '542420',
      capital_marker: '2',
      okato: '03401000000',
      oktmo: '03701000001',
      tax_office: '2300',
      tax_office_legal: '2300',
      timezone: null,
      geo_lat: '45.040216',
      geo_lon: '38.975996',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Воронеж',
    unrestricted_value: '394000, Воронежская обл, г Воронеж',
    data: {
      postal_code: '394000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Центральный',
      region_fias_id: 'b756fe6b-bbd3-44d5-9302-5bfcc740f46e',
      region_kladr_id: '3600000000000',
      region_iso_code: 'RU-VOR',
      region_with_type: 'Воронежская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Воронежская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1',
      city_kladr_id: '3600000100000',
      city_with_type: 'г Воронеж',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Воронеж',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: '5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '3600000100000',
      geoname_id: '472045',
      capital_marker: '2',
      okato: '20401000000',
      oktmo: '20701000001',
      tax_office: '3600',
      tax_office_legal: '3600',
      timezone: null,
      geo_lat: '51.659306',
      geo_lon: '39.196854',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Пермь',
    unrestricted_value: '614000, Пермский край, г Пермь',
    data: {
      postal_code: '614000',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Приволжский',
      region_fias_id: '4f8b1a21-e4bb-422f-9087-d3cbf4bebc14',
      region_kladr_id: '5900000000000',
      region_iso_code: 'RU-PER',
      region_with_type: 'Пермский край',
      region_type: 'край',
      region_type_full: 'край',
      region: 'Пермский',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'a309e4ce-2f36-4106-b1ca-53e0f48a6d95',
      city_kladr_id: '5900000100000',
      city_with_type: 'г Пермь',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Пермь',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: 'a309e4ce-2f36-4106-b1ca-53e0f48a6d95',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '5900000100000',
      geoname_id: '511196',
      capital_marker: '2',
      okato: '57401000000',
      oktmo: '57701000001',
      tax_office: '5900',
      tax_office_legal: '5900',
      timezone: null,
      geo_lat: '58.010259',
      geo_lon: '56.234195',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
  {
    value: 'г Волгоград',
    unrestricted_value: '400066, Волгоградская обл, г Волгоград',
    data: {
      postal_code: '400066',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Южный',
      region_fias_id: 'da051ec8-da2e-4a66-b542-473b8d221ab4',
      region_kladr_id: '3400000000000',
      region_iso_code: 'RU-VGG',
      region_with_type: 'Волгоградская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Волгоградская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'a52b7389-0cfe-46fb-ae15-298652a64cf8',
      city_kladr_id: '3400000100000',
      city_with_type: 'г Волгоград',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Волгоград',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: null,
      street_kladr_id: null,
      street_with_type: null,
      street_type: null,
      street_type_full: null,
      street: null,
      stead_fias_id: null,
      stead_cadnum: null,
      stead_type: null,
      stead_type_full: null,
      stead: null,
      house_fias_id: null,
      house_kladr_id: null,
      house_cadnum: null,
      house_type: null,
      house_type_full: null,
      house: null,
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_cadnum: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      room_fias_id: null,
      room_cadnum: null,
      room_type: null,
      room_type_full: null,
      room: null,
      postal_box: null,
      fias_id: 'a52b7389-0cfe-46fb-ae15-298652a64cf8',
      fias_code: null,
      fias_level: '4',
      fias_actuality_state: '0',
      kladr_id: '3400000100000',
      geoname_id: '472757',
      capital_marker: '2',
      okato: '18401000000',
      oktmo: '18701000001',
      tax_office: '3400',
      tax_office_legal: '3400',
      timezone: null,
      geo_lat: '48.707103',
      geo_lon: '44.516939',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      divisions: null,
      qc_geo: '4',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
  },
];
