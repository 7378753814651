import { sleep } from '@antfu/utils';
import { useToast } from 'vue-toastification';
import { defaultLocations } from '~/constants/defaultLocations';
import { DELIVERY_SERVICES } from '~/constants/deliveryServices';
import { DELIVERY_TYPES } from '~/constants/deliveryTypes';
import { PAYMENT_OPTIONS } from '~/constants/paymentOptions';
import type {
  AvailableDeliveryFullDetails,
  AvailableDeliveryResponse,
  CustomerDetails,
  DeliveryService,
  DeliveryServiceDetailed,
  DeliveryType,
  Location,
  PaymentAndDeliveries,
  PaymentOption,
  PaymentVendor,
  PickupPoint,
  SelectedDeliveryAddress,
  ShopItem,
  SuccessOrderResponse,
} from '~/types/Checkout';
import ToastComponent from '~/components/ui/ToastComponent.vue';

export const initialPaymentsAndDeliveriesState: PaymentAndDeliveries = {
  [PAYMENT_OPTIONS.online]: {
    slug: PAYMENT_OPTIONS.online,
    name: 'Онлайн',
    deliveryTypes: { [DELIVERY_TYPES.russianPost.slug]: structuredClone(DELIVERY_TYPES.russianPost) },
  },
  [PAYMENT_OPTIONS.cash_upon_receipt]: {
    slug: PAYMENT_OPTIONS.cash_upon_receipt,
    name: 'При получении',
    deliveryTypes: {},
  },
};

export const initialSwitchesState = {
  shopPickup: false,
  selfPickup: false,
  courier: false,
  russianPost: false,
};

export const initialDeliveryAddress: SelectedDeliveryAddress = {
  value: '',
  isPrivateHouse: false,
};

export const initialCustomerDetails: CustomerDetails = {
  surname: '',
  name: '',
  patronymic: '',
  phone: '',
  email: '',
  comment: '',
};

export interface CheckoutStore {
  selectedLocation: Location;

  isLoadingDeliveryDetailsList: string[];
  allAvailableDeliveries: string[];
  paymentsAndDeliveries: PaymentAndDeliveries;
  isPaymentsAndDeliveriesLoaded: boolean;

  selectedPaymentOption: PaymentOption;

  deliverySwitches: {
    shopPickup: boolean;
    selfPickup: boolean;
    courier: boolean;
    russianPost: boolean;
  };
  deliveryCost: number;

  selectedDeliveryType: DeliveryType | undefined;
  selectedDeliveryService: DeliveryService | undefined;

  selectedShopToPickup: ShopItem | undefined;
  selectedPickupPoint: PickupPoint | undefined;
  selectedCourierService: DeliveryService | DeliveryServiceDetailed | undefined;
  selectedPostService: DeliveryService | DeliveryServiceDetailed | undefined;

  openMarker: PickupPoint | null;

  selectedDeliveryAddress: SelectedDeliveryAddress;

  availablePaymentVendors: PaymentVendor[];
  selectedPaymentVendor: PaymentVendor | undefined;

  customerDetails: CustomerDetails;

  requiredFieldsErrors: string[];
}

export const useCheckoutStore = createCheckoutStore({
  selectedLocation: defaultLocations[0],

  isLoadingDeliveryDetailsList: [],
  allAvailableDeliveries: [],
  paymentsAndDeliveries: structuredClone(initialPaymentsAndDeliveriesState),
  isPaymentsAndDeliveriesLoaded: false,

  selectedPaymentOption: structuredClone(initialPaymentsAndDeliveriesState.online),

  deliverySwitches: structuredClone(initialSwitchesState),
  deliveryCost: 0,

  selectedDeliveryType: undefined,
  selectedDeliveryService: undefined,

  selectedShopToPickup: undefined,
  selectedPickupPoint: undefined,
  selectedCourierService: undefined,
  selectedPostService: undefined,

  openMarker: null,

  selectedDeliveryAddress: structuredClone(initialDeliveryAddress),

  availablePaymentVendors: [],
  selectedPaymentVendor: undefined,

  customerDetails: structuredClone(initialCustomerDetails),

  requiredFieldsErrors: [],
});

export function createCheckoutStore(initialState: CheckoutStore) {
  return defineStore('checkout', {
    state: () => initialState,

    actions: {
      async getUserLocation() {
        const userLocation = localStorage.getItem('user-location');

        if (userLocation) {
          const storedLocation = JSON.parse(userLocation);

          if (this.selectedLocation.value !== storedLocation.value) {
            this.selectedLocation = storedLocation;
          }

          return;
        }

        try {
          const { data } = await useCustomFetch<{ location: Location }>('/delivery/get-location');

          if (data.value?.location) {
            this.setSelectedLocation(data.value?.location);
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async searchLocations(search: string) {
        const data = await useDaDataFetch<{ suggestions: Location[] }>('/address', {
          method: 'POST',
          body: {
            query: search,
            from_bound: { value: 'city' },
            to_bound: { value: 'settlement' },
            locations: { country_iso_code: 'RU' },
            count: 16,
          },
        });

        return data?.suggestions?.length ? data.suggestions : [];
      },

      setSelectedLocation(location: Location) {
        this.selectedLocation = location;
        localStorage.setItem('user-location', JSON.stringify(location));
      },

      async getAllAvailableDeliveries() {
        try {
          const { data } = await useCustomFetch<{ data: string[] }>('/delivery/all-deliveries');

          if (data?.value?.data) {
            this.allAvailableDeliveries = data.value.data;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async fetchData() {
        await sleep(100);

        for (const deliverySlug of this.allAvailableDeliveries) {
          this.fetchDeliveryDetailsBySlug(deliverySlug);
        }
      },

      async fetchDeliveryDetailsBySlug(deliverySlug: string) {
        this.isLoadingDeliveryDetailsList.push(deliverySlug);

        const data = await useCustomFetchClient<{ data: { [key: string]: AvailableDeliveryResponse } }>(
          '/delivery/available-deliveries',
          {
            method: 'POST',
            key: deliverySlug,
            body: {
              kladr: this.selectedLocation.data.city_kladr_id ?? this.selectedLocation.data.settlement_kladr_id,
              delivery_slug: deliverySlug,
            },
          },
        );

        if (data?.data) {
          const deliveryDetails = { ...Object.values(data?.data)[0], slug: Object.keys(data?.data)[0] };

          await this.processDeliveryDetailsAndFillDeliveries(deliveryDetails);
          await this.getDeliveryServiceDetails(deliverySlug);

          this.selectedPaymentOption = this.paymentsAndDeliveries[this.selectedPaymentOption.slug];
        }

        this.isLoadingDeliveryDetailsList = this.isLoadingDeliveryDetailsList.filter((slug) => slug !== deliverySlug);
      },

      async processDeliveryDetailsAndFillDeliveries(deliveryDetails: AvailableDeliveryFullDetails) {
        Object.keys(this.paymentsAndDeliveries).forEach((paymentOption) => {
          // для магазина
          if (deliveryDetails.slug === DELIVERY_SERVICES.pick_up_from_the_store.slug && deliveryDetails.has_pvz) {
            this.paymentsAndDeliveries[paymentOption].deliveryTypes = {
              shopPickup: structuredClone(DELIVERY_TYPES.shopPickup),
              ...this.paymentsAndDeliveries[paymentOption].deliveryTypes,
            };
            return;
          }

          // для самовывоза из пвз
          if (deliveryDetails.has_pvz) {
            this.paymentsAndDeliveries[paymentOption].deliveryTypes = {
              selfPickup: structuredClone(DELIVERY_TYPES.selfPickup),
              ...this.paymentsAndDeliveries[paymentOption].deliveryTypes,
            };

            const isSelfPickupServiceAdded = this.paymentsAndDeliveries[
              paymentOption
            ].deliveryTypes.selfPickup.availableServices.some((s) => s.slug === deliveryDetails.slug);

            if (!isSelfPickupServiceAdded) {
              this.paymentsAndDeliveries[paymentOption].deliveryTypes.selfPickup.availableServices.push(
                DELIVERY_SERVICES[deliveryDetails.slug],
              );
            }
          }

          // для курьерской доставки по адресу
          if (
            deliveryDetails.payment_options?.includes(paymentOption) &&
            deliveryDetails.has_delivery &&
            deliveryDetails.slug !== DELIVERY_SERVICES.russian_post.slug
          ) {
            // Костыль, т.к. оплаты при получении при доставке нет
            if (paymentOption === PAYMENT_OPTIONS.cash_upon_receipt) return;

            this.paymentsAndDeliveries[paymentOption].deliveryTypes = {
              courier: structuredClone(DELIVERY_TYPES.courier),
              ...this.paymentsAndDeliveries[paymentOption].deliveryTypes,
            };

            const isCourierServiceAdded = this.paymentsAndDeliveries[
              paymentOption
            ].deliveryTypes.courier.availableServices.some((s) => s.slug === deliveryDetails.slug);

            if (!isCourierServiceAdded) {
              this.paymentsAndDeliveries[paymentOption].deliveryTypes.courier.availableServices.push(
                DELIVERY_SERVICES[deliveryDetails.slug],
              );
            }
          }
        });
      },

      async getDeliveryDetailsByOfferId(offerId: number) {
        const data = await useCustomFetchClient<DeliveryServiceDetailed[]>(
          '/delivery/address-delivery-prices-by-offer',
          {
            query: {
              offer_id: offerId,
              kladr: this.selectedLocation.data.city_kladr_id ?? this.selectedLocation.data.settlement_kladr_id,
              postal_code: this.selectedLocation.data.postal_code,
              payment_option: PAYMENT_OPTIONS.online,
            },
          },
          '1',
          true,
        );

        return data && data.length ? data : [];
      },

      async getDeliveryDetailsBySlug(deliverySlug?: string) {
        const data = await useCustomFetchClient<DeliveryServiceDetailed[]>('/delivery/get-address-delivery-prices', {
          query: {
            kladr: this.selectedLocation.data.city_kladr_id ?? this.selectedLocation.data.settlement_kladr_id,
            postal_code: this.selectedLocation.data.postal_code,
            payment_option: PAYMENT_OPTIONS.online,
            delivery_slug: deliverySlug,
          },
        });

        return data && data.length ? data : [];
      },

      async getDeliveryServiceDetails(slug: string) {
        const detailsArray = await this.getDeliveryDetailsBySlug(slug);

        if (detailsArray[0]) {
          const details = detailsArray[0];

          if (slug === DELIVERY_SERVICES.russian_post.slug) {
            this.paymentsAndDeliveries.online.deliveryTypes.russianPost.availableServices[0] = details;

            if (this.selectedDeliveryType?.slug === DELIVERY_TYPES.russianPost.slug) {
              this.deliveryCost = details.delivery_price ?? 0;
            }
          } else if (this.paymentsAndDeliveries.online.deliveryTypes.courier) {
            const services = this.paymentsAndDeliveries.online.deliveryTypes.courier.availableServices;

            for (const i in services) {
              if (services[i].slug === slug) {
                services[i] = details;
              }
            }

            if (this.selectedCourierService && this.selectedCourierService.slug === slug) {
              this.selectedCourierService = details;

              if (this.selectedDeliveryType?.slug === DELIVERY_TYPES.courier.slug) {
                this.deliveryCost = details.delivery_price ?? 0;
              }
            }
          }
        }
      },

      resetDeliveryTypesBlocksState() {
        this.deliverySwitches = structuredClone(initialSwitchesState);
      },

      resetSelectedDeliveriesState() {
        this.selectedDeliveryType = undefined;
        this.selectedDeliveryService = undefined;
        this.selectedPaymentVendor = undefined;
        this.availablePaymentVendors = [];

        this.selectedShopToPickup = undefined;
        this.selectedPickupPoint = undefined;
        this.selectedCourierService = undefined;
        this.selectedPostService = undefined;

        this.selectedDeliveryAddress = structuredClone(initialDeliveryAddress);
      },

      async getPaymentVendors() {
        const data = await useCustomFetchClient<PaymentVendor[]>(
          '/payments/vendors',
          {
            query: {
              delivery_service: this.selectedDeliveryService?.slug,
            },
          },
          '1',
          true,
        );

        if (data && data.length) {
          this.selectedPaymentVendor = data[0];

          this.availablePaymentVendors = data.length > 1 ? data : [];
        }
      },

      async getShops() {
        const data = await useCustomFetchClient<ShopItem[]>('/delivery/get-shops', {
          query: {
            kladr_id: this.selectedLocation.data.city_kladr_id ?? this.selectedLocation.data.settlement_kladr_id,
          },
        });

        return data ?? [];
      },

      async getShopsForProductCard(kladrId: string) {
        const data = await useCustomFetchClient<ShopItem[]>('/delivery/get-shops-without-cart', {
          query: { kladr_id: kladrId },
        });

        return data ?? [];
      },

      async getPickupPoints(deliverySlug: string, offerId?: number) {
        const data = await useCustomFetchClient<PickupPoint[]>('/delivery/get-issuing-points', {
          query: {
            kladr_id: this.selectedLocation.data.city_kladr_id ?? this.selectedLocation.data.settlement_kladr_id,
            offer_id: offerId,
            delivery_slug: deliverySlug,
            payment_option: this.selectedPaymentOption.slug,
          },
        });

        return data ?? [];
      },

      getPickupPointDeliveryCost(pvzId: string) {
        return useCustomFetchClient<{ pvz_id: string; delivery_price: number }>('/delivery/issuing-point/boxberry', {
          query: {
            pvz_id: pvzId,
            payment_option: this.selectedPaymentOption.slug,
          },
        });
      },

      async searchAddress(search: string, isPrivateHouse: boolean) {
        const data = await useDaDataFetch<{ suggestions: Location[] }>('/address', {
          method: 'POST',
          body: {
            query: search,
            from_bound: { value: 'street' },
            to_bound: { value: isPrivateHouse ? 'house' : 'flat' },
            locations: {
              country_iso_code: 'RU',
              city: this.selectedLocation.data.city ?? null,
              region: this.selectedLocation.data.settlement ? this.selectedLocation.data.region : null,
              settlement: this.selectedLocation.data.settlement ?? null,
            },
            count: 20,
          },
        });

        return data?.suggestions.length ? data.suggestions : [];
      },

      async sendOrder(recaptchaToken?: string) {
        let body;
        const orderBody = localStorage.getItem('orderBody');

        if (orderBody) {
          body = JSON.parse(orderBody);
          localStorage.removeItem('orderBody');
        } else {
          body = this.prepareOrderBody();
        }

        try {
          const roistatVisitId = useCookie('roistat_visit');

          const { data } = await useCustomFetch<SuccessOrderResponse>('/payments/site-orders', {
            method: 'POST',
            body,
            query: { recaptchaToken, roistat: roistatVisitId.value },
          });

          const toast = useToast();

          if (data.value && data.value.status === 'success') {
            /* eslint-disable camelcase */
            const { payment_url, post_payment_url, site_order_number } = data.value.data;
            const { appUrl } = useRuntimeConfig().public;
            const { sendPurchaseEventToDataLayer } = useGtmModule();
            const cartStore = useCartStore();

            // @ts-ignore
            await sendPurchaseEventToDataLayer(
              cartStore.cart.items.map((item) => item.data),
              {
                currency: 'RUB',
                value: cartStore.cart.calculate.total,
                shipping: this.deliveryCost,
                affiliation: appUrl,
                transaction_id: site_order_number,
                coupon: cartStore.cart.calculate.promo_code?.code,
              },
            );
            if (import.meta.client) {
              window.roistat?.event?.send('purchase');
              if (window.r46) {
                window.r46('track', 'purchase', {
                  email: this.customerDetails.email,
                  phone: this.customerDetails.phone,
                  products: cartStore.cart.items.map((item) => ({
                    id: item.id,
                    price: item.data.price,
                    amount: item.count,
                  })),
                  order: data.value.data.site_order_number,
                  order_price: cartStore.cart.calculate.total,
                });
              }
              toast.success({ component: ToastComponent, props: { text: data.value.message } });
            }

            cartStore.getCart();

            if (
              (this.selectedDeliveryType?.slug === DELIVERY_TYPES.courier.slug ||
                this.selectedDeliveryType?.slug === DELIVERY_TYPES.russianPost.slug) &&
              (this.selectedDeliveryService?.slug === DELIVERY_SERVICES['express-mkad'].slug ||
                this.selectedDeliveryService?.status !== 'success')
            ) {
              await navigateTo(makeUrlRelative(post_payment_url) ?? '/profile');
            }

            if (this.selectedPaymentOption.slug === PAYMENT_OPTIONS.online && payment_url) {
              await navigateTo(payment_url, { external: true });
            }

            await navigateTo(makeUrlRelative(post_payment_url) ?? '/profile');

            return data.value.status;
            /* eslint-enable camelcase */
          } else if (data.value && data.value.status === 'error') {
            toast.error({ component: ToastComponent, props: { text: data.value.message } });
            return data.value.status;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return '';
        }
      },

      validateFields() {
        this.requiredFieldsErrors = [];

        const isShopSelected =
          this.selectedDeliveryType?.slug === DELIVERY_TYPES.shopPickup.slug && this.selectedShopToPickup;

        const isSelfPickupSelected =
          this.selectedDeliveryType?.slug === DELIVERY_TYPES.selfPickup.slug && this.selectedPickupPoint;

        const isCourierSelected =
          this.selectedDeliveryType?.slug === DELIVERY_TYPES.courier.slug &&
          this.selectedDeliveryAddress.value &&
          this.selectedCourierService;

        const isRussianPostSelected =
          this.selectedDeliveryType?.slug === DELIVERY_TYPES.russianPost.slug &&
          this.selectedDeliveryAddress.value &&
          this.selectedPostService;

        if (
          (this.selectedDeliveryType?.slug === DELIVERY_TYPES.courier.slug ||
            this.selectedDeliveryType?.slug === DELIVERY_TYPES.russianPost.slug) &&
          (!this.selectedDeliveryAddress.value || this.selectedDeliveryAddress.value.length < 4)
        )
          this.requiredFieldsErrors.push('deliveryAddress');

        if (!isShopSelected && !isSelfPickupSelected && !isCourierSelected && !isRussianPostSelected)
          this.requiredFieldsErrors.push('deliveryType');

        if (!this.customerDetails.surname) this.requiredFieldsErrors.push('surname');
        if (!this.customerDetails.name) this.requiredFieldsErrors.push('name');
        if (
          !this.customerDetails.patronymic &&
          (this.selectedDeliveryType?.slug === DELIVERY_TYPES.russianPost.slug ||
            (this.selectedDeliveryType?.slug === DELIVERY_TYPES.selfPickup.slug &&
              this.selectedPickupPoint?.delivery_provider === DELIVERY_SERVICES.sdek.slug) ||
            (this.selectedDeliveryType?.slug === DELIVERY_TYPES.courier.slug &&
              (this.selectedCourierService?.slug === DELIVERY_SERVICES.sdek.slug ||
                this.selectedCourierService?.slug === DELIVERY_SERVICES.ems.slug)))
        ) {
          this.requiredFieldsErrors.push('patronymic');
        }
        if (
          this.customerDetails.phone.replace(/\D/g, '').length < 11 ||
          this.customerDetails.phone.replace(/\D/g, '').slice(1, 2) !== '9'
        )
          this.requiredFieldsErrors.push('phone');

        if (
          this.requiredFieldsErrors.includes('deliveryType') ||
          this.requiredFieldsErrors.includes('deliveryAddress')
        ) {
          window.scrollTo(0, 240);
        } else if (this.requiredFieldsErrors.length !== 0) {
          window.scrollTo(0, 770);
        }

        return !this.requiredFieldsErrors.length;
      },

      prepareOrderBody() {
        /// /////////////////////////////////////////////////////////////////////////
        //  вся эта дичь из-за легаси бэка

        const cartStore = useCartStore();

        const addressForDeliveryAndRussianPost = {
          apartment: this.selectedDeliveryAddress.data?.flat ?? '',
          city:
            (this.selectedDeliveryAddress.data?.city || this.selectedDeliveryAddress.data?.settlement) ??
            (this.selectedLocation.data.city || this.selectedLocation.data.settlement),
          city_fias_id: this.selectedLocation.data.city_fias_id ?? '',
          city_kladr_id: this.selectedLocation.data.city_kladr_id ?? '',
          comment: '',
          geo_lat: this.selectedLocation.data.geo_lat ?? '',
          geo_lon: this.selectedLocation.data.geo_lon ?? '',
          geoname_id: this.selectedLocation.data.geoname_id ?? '',
          house: this.selectedDeliveryAddress.data?.house ?? '',
          postal_code: this.selectedDeliveryAddress.data?.postal_code ?? this.selectedLocation.data.postal_code,
          private_house: this.selectedDeliveryAddress.isPrivateHouse,
          street: this.selectedDeliveryAddress.data?.street ?? this.selectedDeliveryAddress.value,
          title: this.selectedDeliveryAddress.value,
          title_house: this.selectedDeliveryAddress.data?.house
            ? `${this.selectedDeliveryAddress.data?.house_type} ${this.selectedDeliveryAddress.data?.house}`
            : '',
          title_street: this.selectedDeliveryAddress.data?.street
            ? `${this.selectedDeliveryAddress.data?.street_type} ${this.selectedDeliveryAddress.data?.street}`
            : this.selectedDeliveryAddress.value,
        };

        let activeDeliveryType;
        let deliveryCompany;
        let deliveryAddress;
        let pickupPoint = null;

        if (this.selectedDeliveryType?.slug === DELIVERY_TYPES.shopPickup.slug && this.selectedShopToPickup) {
          // магазин
          activeDeliveryType = 'store_issuing_point';
          deliveryCompany = this.selectedShopToPickup.code;
          deliveryAddress = { title: this.selectedShopToPickup.address };
          pickupPoint = this.selectedShopToPickup;
        } else if (this.selectedDeliveryType?.slug === DELIVERY_TYPES.selfPickup.slug && this.selectedPickupPoint) {
          // самовывоз
          activeDeliveryType = 'issuing_point';
          deliveryCompany = this.selectedPickupPoint.delivery_provider;
          deliveryAddress = { title: this.selectedPickupPoint.address };
          pickupPoint = this.selectedPickupPoint;
        } else if (
          // курьер
          this.selectedDeliveryType?.slug === DELIVERY_TYPES.courier.slug &&
          this.selectedDeliveryAddress.value &&
          this.selectedCourierService
        ) {
          activeDeliveryType = 'delivery';
          deliveryCompany = this.selectedCourierService.slug;
          deliveryAddress = addressForDeliveryAndRussianPost;
        } else if (
          // почта россии
          this.selectedDeliveryType?.slug === DELIVERY_TYPES.russianPost.slug &&
          this.selectedDeliveryAddress.value
        ) {
          activeDeliveryType = 'delivery';
          deliveryCompany = DELIVERY_SERVICES.russian_post.slug;
          deliveryAddress = addressForDeliveryAndRussianPost;
        } else {
          // default
          activeDeliveryType = null;
          deliveryCompany = null;
          deliveryAddress = null;
        }

        return {
          contacts: {
            name: this.customerDetails.name,
            second_name: this.customerDetails.surname,
            third_name: this.customerDetails.patronymic,
            phone: `+${this.customerDetails.phone.replace(/\D/g, '')}`,
            email: this.customerDetails.email,
            comment: this.customerDetails.comment,
          },
          country: this.selectedLocation.data.country,
          dadata_address: this.selectedDeliveryAddress.data
            ? this.selectedDeliveryAddress.data
            : this.selectedLocation.data,
          delivery_address: activeDeliveryType === 'delivery' ? deliveryAddress : null,
          delivery_company: deliveryCompany,
          grab_type: activeDeliveryType,
          has_no_dadata_address: activeDeliveryType === 'delivery' ? !this.selectedDeliveryAddress.data : null,
          issuing_point_address: pickupPoint,
          payment_type: this.selectedPaymentOption.slug,
          payment_vendor: this.selectedPaymentVendor?.payment_vendor,
          payment_way: this.selectedPaymentVendor?.payment_way,
          pickup_option: activeDeliveryType === 'store_issuing_point' ? this.selectedShopToPickup?.code : null,
          promo_code: cartStore.cart.calculate.promo_code ?? null,
          user_location: {
            city: this.selectedLocation.data.city,
            city_fias_id: this.selectedLocation.data.city_fias_id,
            city_kladr_id: this.selectedLocation.data.city_kladr_id,
            country: this.selectedLocation.data.country,
            full_object: { data: this.selectedLocation.data },
            geo_lat: this.selectedLocation.data.geo_lat,
            geo_lon: this.selectedLocation.data.geo_lon,
            postal_code: this.selectedLocation.data.postal_code,
            settlement: this.selectedLocation.data.settlement,
            settlement_fias_id: this.selectedLocation.data.settlement_fias_id,
            settlement_kladr_id: this.selectedLocation.data.settlement_kladr_id,
            title: this.selectedLocation.data.title,
          },
        };
      },
    },
  });
}
