import { useToast } from 'vue-toastification';
import ToastComponent from '~/components/ui/ToastComponent.vue';

export async function useDaDataFetch<T>(url: string, options: any = {}) {
  try {
    const { daDataUrl, daDataToken } = useRuntimeConfig().public;

    const data = await $fetch<T>(url, {
      baseURL: daDataUrl,
      headers: {
        Authorization: `Token ${daDataToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    });

    if (data) return data;
  } catch (e: any) {
    if (e && e.statusCode) {
      console.error(e);

      if (import.meta.client && [401, 403, 500, 502].includes(e.statusCode) && e.data?.message) {
        const toast = useToast();
        toast.error({ component: ToastComponent, props: { text: e.data.message } });
      }
    }
  }
}
